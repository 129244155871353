import React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/layout-hr'

import { SEO } from "../components/seo"

import comingSoon from "../images/coming-soon.jpg"

const home = () => {
  return (
    <div>
         <Layout>
              <div className="container page-width center">

                  <img className="octopus" src={comingSoon} alt="Hrvatska verzija stranica je u izradi." />
                  <h2>Hrvatska verzija stranica je u izradi. </h2>
                  <h3 className='subtitle'>Vidimo se uskoro.</h3>
                  <p className='mt20 italic'>P.S. Dio blog postova je na hrvatskom. <a href="https://blog.north-northwest.com/hr/">Bacite oko.</a></p>
                 

              </div>       
              
         </Layout>
    </div>
  )
  }

  export default home

  export const Head = () => (
    <SEO />
  )
  
  