import React from 'react'

import { Link } from 'gatsby'

import logo from "../images/logo.png"


const Header = () => {
    return (
        <header>
            <div className="header-content page-width">
                <h1><strong>North-NorthWest</strong> - <span>a web development studio</span></h1>
                <Link to="/" className='logo'><img src={logo} alt="Logo" /></Link>
                <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        <li><a href="https://blog.north-northwest.com/">Blog</a></li>
                    </ul>

                    <ul className="language-selector">
                    <li><Link to="/" >EN</Link></li>
                    <li><Link to="/index-croatian"className="language-selected">HR</Link></li>
                </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header